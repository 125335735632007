export default {
	index: {
		title: 'Оптичен Интернет и Интерактивна Телевизия за всеки ',
		metaDescription:
			'Вземи своя пакет оптичен интернет и телевизия на супер цена от Netsurf. Покриваме те в Монтана, Враца, Благоевград, Лом, Вършец, Козлодуй, Берковица и др.'
	},
	home: {
		title: 'Интернет и Телевизия За Дома на супер цени ',
		metaDescription:
			'Възползвай се от най-добрите предложения за интернет и телевизия за твоя дом от Netsurf. Получаваш ултра висока скорост и много  програми. Увери се!'
	},
	homeIndefinite: {
		title: 'Домашен Интернет и Телевизия Без Договор',
		metaDescription:
			'Вземи своя нов пакет Оптичен Интернет и Интерактивна Телевизия Без Договор в Монтана, Благоевград, Враца, Лом, Берковица, Вършец, Оряхово и много други.'
	},
	business: {
		title: 'Надеждният интернет и телевизия за твоя бизнес ',
		metaDescription:
			'С Netsurf получаваш сигурен интернет и страхотна интерактивна телевизия за офиса. Довери се на най-надеждния партньор за твоя бизнес.'
	},
	coverage: {
		title: 'Покритие нашите услуги ',
		metaDescription:
			'Покриваме те в Монтана, Враца, Благоевград, Лом, Вършец, Козлодуй, Берковица, Оряхово, Кочериново, Селановци и още много други. Виж всики локации Тук!'
	},
	shops: {
		title: 'Нашите магазини и офиси в България ',
		metaDescription:
			'Очакваме те в най-близкия до теб офис на Netsurf в Монтана, Враца, Благоевград, Лом, Козлодуй и Берковица.'
	},
	terms: {
		title: 'Общи условия и Бланки',
		metaDescription:
			'Вашата поверителност е важна за нас и следователно е прозрачна за това как събираме, използваме и споделяме информация за вас. '
	},
	configurator: {
		title: 'Конфигуратор',
		metaDescription:
			'Подбери най-точния пакет лично за теб от свръхмодерен оптичен интернет и цифрова телевизия, като отговориш на само няколко въпроса! NetSurf мисли за теб.'
	},
	notfound: {
		title: 'Грешка 404',
		metaDescription:
			'Съвсем скоро ще видиш търсеното от теб съдържание. NetSurf ще те покрие с ултрамодерен GPON оптичен интерет и интерактивна телевизия.'
	},
	promo: {
		title: 'Промоции за Интернет и Телевизия',
		metaDescription:
			'Вземи своя промо пакет с неустоими предложения от Netsurf. Най-добрите условия с най-бързата скорост и много програми на страхотни цени само при нас.'
	},
	about: {
		title: 'Защо да избереш NetSurf',
		metaDescription:
			'Над 23 000 доволни клиента и вече над 25 години предлагаме най-качествените услуги за дома и бизнеса. Довери ни се!'
	},
	kakvoEGpon: {
		title: 'GPON оптичен Интернет и Телевизия',
		metaDescription:
			'Бъди неподвластен на времето с нашия ултра бърз интернет GPON.  Сърфираш с до 1000 Mbit/s и гледаш телевизия, дори когато няма ток. Опитай!'
	},
	netsurfplay: {
		title: 'Netsurf Play | Телевизия за твоя телефон',
		metaDescription:
			'Свали Netsurf Play напълно безплатно и се наслаждавай любимите си канали от телефона. Над 30 канала на живо в цялата страна.'
	},
	faq: {
		title: 'Помощ за всичките ти въпроси ',
		metaDescription:
			'Намери отговора на всеки твой въпрос в нашия сайт или по телефона. Нашият екип е винаги на твое разположение. Очакваме те!'
	},
	devices: {
		title: 'Онлайн Магазин за Устройства за ТВ и Интернет',
		metaDescription:
			'Избери продукт на изгодна цена от широката гама промоции на телевизори и таблети в новия ни онлайн магазин! Не е за вярване, а направо си е за действие!'
	},
	careers: {
		title: 'Кариери и свободни позиции ',
		metaDescription:
			'Присъедини се към нашия сплотен и млад екип. Очакваме те на нашия сайт с всички отворени позиции.'
	},
	assistant: {
		title: 'С NetSurf Assistant за да фиксваш вече и сам проблемите с нета си',
		metaDescription:
			'Новаторско приложение от NetSurf, с което разрешаваш проблемите с интернета светкавично. Изпробвай го! '
	}
};

